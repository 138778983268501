import { supabase } from "./supabaseConnect";

export default async function supabaseSignInWithOtp(email) {

    const { data, error } = await supabase.auth.signInWithOtp({
        email: email,
        options: {
            shouldCreateUser: false
        }
    })
    return { data, error };
}

