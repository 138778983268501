import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ListGroup, Button, ButtonGroup, Form, Modal, Popover, OverlayTrigger } from 'react-bootstrap';
import { getKey, updateKey } from '../functions/keys';
import copyTextToClipboard from '../../../functions/copyTextToClipboard';

export default function Key({ updateProjectKeysList, activeKey }) {
    const navigate = useNavigate();
    const { keyId } = useParams();

    // const [key, setKey] = useState([]);
    const [key, setKey] = useState(activeKey);

    const [keyName, setKeyName] = useState(activeKey.name);
    const [keyAddress, setKeyAddress] = useState(activeKey.address);
    const [keyLogin, setKeyLogin] = useState(activeKey.login);
    const [keyPassword, setKeyPassword] = useState(activeKey.password);

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const setKeyObject = (keyId) => {
        getKey(keyId).then((result) => {
            setKey(result);
            setKeyName(result.name);
            setKeyAddress(result.address);
            setKeyLogin(result.login);
            setKeyPassword(result.password);
        });
    }

    useEffect(()=>{
        setKey();
        setKeyObject(keyId);
    }, [keyId]);


    useEffect(()=>{
        setKeyName(key?.name);
        setKeyAddress(key?.address);
        setKeyLogin(key?.login);
        setKeyPassword(key?.password);;
    }, [key]);

    useEffect(()=>{
        setKey(activeKey);
    }, [activeKey]);

    const editKey = async (keyId, keyData) => {
        updateKey(keyId, keyData).then((result) => {
            if (result.active !== false) {
                setKeyObject(keyId);
            } else {
                navigate('../');
            }
            updateProjectKeysList(result.projectId);
        });
    };

    const editKeyFormSubmit = (e) => {
        e.preventDefault();
        editKey(keyId, {
            name: keyName,
            address: keyAddress,
            login: keyLogin,
            password: keyPassword  
        }).then(() => {
            handleClose();
        });
    };

    const archiveKey = () => {
        editKey(keyId, {
            active: false
        });
    }

    const approvalPopover = (
        <Popover>
            <ButtonGroup>
                <Button variant='default' className='btn-sm' onClick={archiveKey} title='Подтвердить архивацию ключа'>
                    <i className="bi bi-check-lg" onClick={archiveKey}></i>
                </Button>
            </ButtonGroup>
        </Popover>
    );

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className='h4 mb-0'>{key?.name}</h2>
                <ButtonGroup>
                    <Button variant='default' className='btn-sm' onClick={handleShow} title='Редактировать ключ'>
                        <i className="bi bi-pencil"></i>
                    </Button>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={approvalPopover} rootClose>
                        <Button variant='default' className='btn-sm' title='Архивировать ключ?'>
                            <i className="bi bi-archive"></i>
                        </Button>
                    </OverlayTrigger>
                </ButtonGroup>
            </div>

            <hr />

            <ListGroup as="ul" className='mb-3'>
                
                {key?.address?.length > 0 &&
                    <ListGroup.Item as="li">
                        <div className="fw-semibold">Адрес:</div>
                        { key?.address?.includes('http')
                            ? 
                            <a 
                                href={key?.address} 
                                target={'_blank'} 
                                rel="noreferrer" 
                                className='key-value text-body'
                            > 
                                {key?.address}
                            </a>
                            : 
                            <span
                                onDoubleClick={e => { copyTextToClipboard(e.target) }}
                                className='key-value'
                            > 
                                {key?.address}
                            </span>
                        }
                    </ListGroup.Item>
                }
                {key?.login?.length > 0 &&
                <ListGroup.Item as="li">
                    <div className="fw-semibold">Логин:</div>
                        <span
                            onDoubleClick={e => { copyTextToClipboard(e.target) }}
                            className='key-value'
                        >
                            {key?.login}
                        </span>
                </ListGroup.Item>
                }
                {key?.password?.length > 0 &&
                <ListGroup.Item as="li">
                    <div className="fw-semibold">Пароль:</div>
                    <span 
                        className='key-value'
                        onDoubleClick={e => { copyTextToClipboard(e.target)}}
                    >
                        {key?.password}
                    </span>
                </ListGroup.Item>
                }
            </ListGroup>


            <Modal show={showModal} onHide={handleClose}>
                <Form onSubmit={editKeyFormSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title> Редактирование ключа </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="keyName">
                            <Form.Label>Название ключа</Form.Label>
                            <Form.Control type="text" placeholder="Введите название ключа" value={keyName} onChange={e => setKeyName(e.target.value)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="keyAddress">
                            <Form.Label>Адрес</Form.Label>
                            <Form.Control type="text" placeholder="Введите адрес" value={keyAddress} onChange={e => setKeyAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="keyLogin">
                            <Form.Label>Логин</Form.Label>
                            <Form.Control type="text" placeholder="Введите логин" value={keyLogin} onChange={e => setKeyLogin(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="keyPassword">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control type="text" placeholder="Введите пароль" value={keyPassword} onChange={e => setKeyPassword(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type="submit">
                            Обновить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            
        </>
    );    
}