import { createClient } from '@supabase/supabase-js';

const supabaseURL = 'https://iiyjhmkxgoksdynvrjov.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlpeWpobWt4Z29rc2R5bnZyam92Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzk4MTc2NzcsImV4cCI6MTk5NTM5MzY3N30.ACsrSAGnoWO8LSYNPqsQzTzR6AFEXjlN28APnXhI1hI';

const supabase = createClient(supabaseURL, supabaseAnonKey);

const logRequest = (method, url, data) => {
    console.log(`Request: ${method} ${url}`, data);
};

const originalFrom = supabase.from.bind(supabase);
supabase.from = (...args) => {
    const [table] = args;
    const request = originalFrom(...args);
    const originalSelect = request.select.bind(request);

    request.select = (...selectArgs) => {
        logRequest('SELECT', table, selectArgs);
        return originalSelect(...selectArgs);
    };

    const originalInsert = request.insert.bind(request);
    request.insert = (...insertArgs) => {
        logRequest('INSERT', table, insertArgs);
        return originalInsert(...insertArgs);
    };

    // Добавьте аналогичные функции для других методов, таких как update, delete и т.д.

    return request;
};

export { supabase };