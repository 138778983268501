
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const ThemeContext = React.createContext()

function ThemeProvider({ children }) {
    
    const [cookies, setCookie] = useCookies(['theme']);

    const [theme, setTheme] = useState(cookies.theme);

    const toggleTheme = () => {
        if (theme === 'dark') {
            setTheme('light');
            setThemeCookie('light');
        } else {
            setTheme('dark');
            setThemeCookie('dark');
        }
    }

    const setThemeCookie = (theme) => {
        setCookie('theme', theme, { 
            path: '/',
            maxAge: 3600000
        });
    }

    useEffect(() => {
        document.body.setAttribute('data-bs-theme', theme);
    }, [theme])
    

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

const useThemeContext = () => React.useContext(ThemeContext)

export { useThemeContext, ThemeProvider }