import { useAuthContext } from "./AuthProvider"
import { Navigate } from "react-router-dom"

export default function RequireAuth({ children, role }) {

    const {session} = useAuthContext();    

    if (!session) {
        console.log('no session');
        return <Navigate to='/login' />
    }

    return children
}