import { useEffect, useState } from 'react';
import { useParams, Route, Routes, NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Modal, Button, Form, InputGroup, Spinner, ButtonGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

import Key from './Key';
import { getProject, updateProject } from '../functions/projects';
import { getProjectKeys, insertKey } from '../functions/keys';

export default function Project({ activeProject, updateProjects }) {
    const navigate = useNavigate();
    const { projectId } = useParams();

    const [project, setProject] = useState(activeProject);
    const [keys, setKeys] = useState([]);
    const [activeKey, setActiveKey] = useState({});

    const [searchPrompt, setSearchPrompt] = useState('');
    const [filteredKeys, setFilteredKeys] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const [newKeyName, setNewKeyName] = useState('');
    const [newKeyAddress, setNewKeyAddress] = useState('');
    const [newKeyLogin, setNewKeyLogin] = useState('');
    const [newKeyPassword, setNewKeyPassword] = useState('');

    const [projectName, setProjectName] = useState(activeProject?.name);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const setProjectObject = (projectId) => {
        getProject(projectId).then((result) => {
            setProject(result);
            setProjectName(result.name);
        });
    };

    useEffect(() => {
        if (!activeProject || Object.keys(activeProject).length === 0) {
            setProjectObject(projectId);
        } else {
            setProject(activeProject);
            setProjectName(activeProject.name);
        }
    }, [activeProject, projectId]);

    const setKeysList = (projectId) => {
        getProjectKeys(projectId).then((result) => {
            setKeys(result);
        });
    };

    useEffect(() => {
        setKeys([]);
        setKeysList(projectId);
    }, [projectId]);

    useEffect(() => {
        setFilteredKeys(
            keys?.filter(x => x.name?.toLowerCase().includes(searchPrompt.toLowerCase()))
        );
    }, [searchPrompt, keys]);

    const createNewKey = (keyData) => {
        insertKey(keyData).then((result) => {
            setNewKeyName('');
            setNewKeyAddress('');
            setNewKeyLogin('');
            setNewKeyPassword('');
            handleClose();
            setKeysList(projectId);
            navigate('./' + result.keyId);
        });
    };

    function addNewKeySubmit(e) {
        e.preventDefault();
        createNewKey({
            projectId: projectId,
            name: newKeyName,
            address: newKeyAddress,
            login: newKeyLogin,
            password: newKeyPassword,
        });
    }

    const editProject = async (projectId, projectData) => {
        const result = await updateProject(projectId, projectData);
        setProjectObject(result);
    };

    const editProjectFormSubmit = async (e) => {
        e.preventDefault();
        await editProject(projectId, {
            name: projectName
        });
        handleEditClose();
    };

    const archiveProject = () => {
        editProject(projectId, {
            active: false
        });
        updateProjects();
        navigate('../');
    };

    const approvalPopover = (
        <Popover>
            <ButtonGroup>
                <Button variant='default' className='btn-sm' onClick={archiveProject} title='Подтвердить архивацию проекта'>
                    <i className="bi bi-check-lg"></i>
                </Button>
            </ButtonGroup>
        </Popover>
    );

    return (
        <>
            <Row className='flex-grow-1'>

                <Col lg={4} className='vstack'>

                    <div className="d-flex align-items-center justify-content-between">
                        <h1 className='h4 mb-0'>
                            {project?.name}
                        </h1>
                        <div>
                            <Button variant='default' className='btn-sm' onClick={handleEditShow} title='Редактировать проект'>
                                <i className="bi bi-pencil"></i>
                            </Button>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={approvalPopover} rootClose>
                                <Button variant='default' className='btn-sm' title='Архивировать проект'>
                                    <i className="bi bi-archive"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>

                    <hr />

                    <ListGroup as='div' variant='secondary' className='mb-3 flex-grow-1'>
                        <ListGroup.Item className='p-0'>
                            <InputGroup>
                                <Form.Control
                                    key={projectId}
                                    autoFocus
                                    className='border-0 bi'
                                    placeholder='&#xF52A;  Поиск ключа...'
                                    onDoubleClick={(e) => {
                                        setSearchPrompt('');
                                    }}
                                    onChange={(e) => {
                                        setSearchPrompt(e.target.value);
                                        setNewKeyName(e.target.value);
                                    }}
                                    value={searchPrompt}
                                />
                                <Button
                                    variant="outline-muted"
                                    style={{ borderBottomRightRadius: 0 }}
                                    onClick={handleShow}
                                    title="Добавить ключ"
                                >
                                    <i className="bi bi-plus-lg"></i>
                                </Button>
                            </InputGroup>
                        </ListGroup.Item>
                        <div className='flex-grow-1 h-0 overflow-hidden'>

                            <SimpleBar style={{ maxHeight: '100%' }}>
                                {keys.length > 0 ?
                                    filteredKeys?.map((key) => (
                                        <NavLink
                                            className={'list-group-item list-group-item-action'}
                                            to={'./' + key.keyId}
                                            key={key.keyId}
                                            onClick={() => { setActiveKey(key); }}
                                        >
                                            {key.name}
                                        </NavLink>
                                    )) :

                                    <div className="list-group-item">
                                        <Spinner animation="grow" role="status" size='sm'>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                }
                            </SimpleBar>

                        </div>
                    </ListGroup>

                </Col>

                <Col lg={8}>
                    <Routes>
                        <Route path=":keyId//*" element={
                            <Key
                                updateProjectKeysList={setKeysList}
                                activeKey={activeKey}
                            />
                        } />
                    </Routes>
                </Col>

            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Form onSubmit={addNewKeySubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{project?.name} - новый ключ </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="newKeyName">
                            <Form.Label>Название ключа</Form.Label>
                            <Form.Control type="text" placeholder="Введите название ключа" value={newKeyName} onChange={e => setNewKeyName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newKeyAddress">
                            <Form.Label>Адрес</Form.Label>
                            <Form.Control type="text" placeholder="Введите адрес" value={newKeyAddress} onChange={e => setNewKeyAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newKeyLogin">
                            <Form.Label>Логин</Form.Label>
                            <Form.Control type="text" placeholder="Введите логин" value={newKeyLogin} onChange={e => setNewKeyLogin(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newKeyPassword">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control type="text" placeholder="Введите пароль" value={newKeyPassword} onChange={e => setNewKeyPassword(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type="submit">
                            Добавить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={editModal} onHide={handleEditClose}>
                <Form onSubmit={editProjectFormSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактирование проекта</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="projectName">
                            <Form.Label>Название проекта</Form.Label>
                            <Form.Control type="text" placeholder="Введите название проекта" value={projectName} onChange={e => setProjectName(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type="submit">
                            Обновить
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
