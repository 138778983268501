import { Row, Col } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import ProjectsList from '../components/ProjectsList';
import Project from '../components/Project';
import { useState, useEffect } from 'react';
import { getProjects } from '../functions/projects';

export default function ProjectsPage() {
    const [activeProject, setActiveProject] = useState([]);
    const [projects, setProjects] = useState([]);

    function updateProjects() {
        getProjects().then((result) => {
            setProjects(result);
        });
    }

    useEffect(() => {
        updateProjects();
    }, []);

    return (
        <>

            <Row className='flex-grow-1'>
                <Col lg={3} className='vstack flex-grow-1'>
                    <ProjectsList projects={projects} updateProjects={updateProjects} setActiveProject={setActiveProject}/>
                </Col>
                <Col lg={9} className='vstack flex-grow-1'>
                    <Routes>
                        <Route
                            path=":projectId//*"
                            element={<Project activeProject={activeProject} updateProjects={updateProjects} />}
                        />
                    </Routes>
                </Col>
            </Row>
        </>
    );    
}