import * as React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./features/auth/pages/Login";
import ProjectsPage from "./features/projects/pages/ProjectsPage";
import Home from "./features/home/Home";
import 'simplebar-react/dist/simplebar.min.css';
import './assets/scss/style.scss';
import RequireAuth from './features/auth/components/RequireAuth';


function NotFoundPage() {
    return (<div>Not Found</div>)
}

export default function App() {

    return (
        <Routes>
            <Route path="/" element={<RequireAuth><Home /></RequireAuth>  }>

                <Route
                    path="projects//*"
                    element={ <ProjectsPage /> }
                />

            </Route>
            
            <Route path="/login/" element={<Login />} />
                
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    )
}