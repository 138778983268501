import { 
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Button
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthContext } from "../../auth/components/AuthProvider";
// import RequireAuth from '../../auth/components/RequireAuth';
import { useThemeContext } from '../../theming/components/ThemeProvider';


export default function MainNav() {

    const { user, signOut } = useAuthContext();

    const { theme, toggleTheme } = useThemeContext()

    async function onClickSignOut() {
        signOut();
    }


    return (
        <Navbar expand="lg" className="mb-3 bg-body-tertiary">
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand>ArkCore</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/projects/">
                            <Nav.Link>Проекты</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav>
                        <Button variant='link' onClick={e => { toggleTheme()}}>
                            {theme === 'dark' ? <i className="bi bi-lightbulb-fill"></i> : <i className="bi bi-lightbulb-off"></i>}
                        </Button>
                        <NavDropdown title={user?.email} id="basic-nav-dropdown">

                            <NavDropdown.Item onClick={onClickSignOut}>
                                Выйти <i className="bi bi-box-arrow-right"></i>
                            </NavDropdown.Item>
                            
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}