import * as React from 'react'
import {supabase} from '../../supabase/functions/supabaseConnect'

const SupabaseContext = React.createContext()

function SupabaseProvider({ children }) {

    return (
        <SupabaseContext.Provider value={{ supabase }}>
            {children}
        </SupabaseContext.Provider>
    )
}

const useSupabaseContext = () => React.useContext(SupabaseContext)

export { useSupabaseContext, SupabaseProvider }