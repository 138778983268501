import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import signInWithOtp from '../functions/signInWithOtp';
import signInWithPassword from '../functions/signInWithPassword';

export default function Login() {
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    async function handleLogInWithPasswordSubmit(e) {
        e.preventDefault();
        setIsDisabled(true);
        setValidated(false);
        setError('');

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setIsDisabled(false);
        } else {
            const { error } = await signInWithPassword(userEmail, userPassword);
            setError(error?.message);
            setValidated(!error);
            setIsDisabled(false);
        }
    }

    async function handleLogInWithOtpSubmit(e) {
        e.preventDefault();
        setIsDisabled(true);
        setValidated(false);
        setError('');

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setIsDisabled(false);
        } else {
            const { error } = await signInWithOtp(userEmail);
            setError(error?.message);
            setValidated(!error);
            setIsDisabled(false);
        }
    }

    return (
        <div className="container">
            <div className='row justify-content-center align-items-center mt-5'>
                <div className="col-lg-4">
                    <Card>
                        <Card.Body>
                            <h1 className="h4">ArkCore</h1>
                            <p>Авторизоваться через:</p>
                            <Tabs
                                defaultActiveKey="password"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="password" title="Пароль">
                                    <Form onSubmit={handleLogInWithPasswordSubmit}>
                                        <Form.Group className="mb-3" controlId="userEmail">
                                            <Form.Control
                                                type="email"
                                                placeholder="Введите ваш емейл"
                                                value={userEmail}
                                                onChange={e => setUserEmail(e.target.value)}
                                                required
                                                isValid={validated && !error}
                                                isInvalid={!!error}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="userPassword">
                                            <Form.Control
                                                type="password"
                                                placeholder="Введите ваш пароль"
                                                value={userPassword}
                                                onChange={e => setUserPassword(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <div className="text-end">
                                            <Button variant="primary" type="submit" disabled={isDisabled}>
                                                Авторизоваться <i className="bi bi-arrow-right"></i>
                                            </Button>
                                        </div>
                                    </Form>
                                </Tab>
                                <Tab eventKey="otp" title="Ссылку в письме">
                                    <p>
                                        На ваш емейл придёт письмо,
                                        <br />
                                        перейдите по ссылке из письма.
                                    </p>
                                    <Form onSubmit={handleLogInWithOtpSubmit}>
                                        <Form.Group className="mb-3" controlId="userEmailOtp">
                                            <Form.Control
                                                type="email"
                                                placeholder="Введите ваш емейл"
                                                value={userEmail}
                                                onChange={e => setUserEmail(e.target.value)}
                                                required
                                                isValid={validated && !error}
                                                isInvalid={!!error}
                                            />
                                            <Form.Control.Feedback type="valid">Письмо отправлено!</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="text-end">
                                            <Button variant="primary" type="submit" disabled={isDisabled}>
                                                Получить письмо <i className="bi bi-arrow-right"></i>
                                            </Button>
                                        </div>
                                    </Form>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}
