import InputGroup from 'react-bootstrap/InputGroup';
import { ListGroup, Form, Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { insertProject } from '../functions/projects';
import SimpleBar from 'simplebar-react';


export default function ProjectsList({ projects, updateProjects, setActiveProject }) {
    const navigate = useNavigate();

    const [searchPrompt, setSearchPrompt] = useState('');
    const [newProjectName, setNewProjectName] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        setFilteredProjects(
            projects?.filter(x => x.name.toLowerCase().includes(searchPrompt.toLowerCase()))
        );
    }, [searchPrompt, projects]);

    function onSubmitCreateNewProject(e) {
        e.preventDefault();
        insertProject(newProjectName).then((result) => {
            setNewProjectName('');
            handleClose();
            updateProjects();
            setActiveProject(result);
            navigate('./' + result.projectId);
        });
    }
    
    return (
        <>
            <ListGroup as='div' className='mb-3 vstack flex-grow-1'>
                
                <ListGroup.Item className='p-0'>
                    <InputGroup>
                        <Form.Control
                            key={1}
                            autoFocus
                            className='border-0 bi'
                            placeholder='&#xF52A;  Поиск проекта...'
                            onDoubleClick={(e) => {
                                setSearchPrompt('');
                            }}
                            onChange={(e) => {
                                setSearchPrompt(e.target.value);
                                setNewProjectName(e.target.value);
                            }}
                            value={searchPrompt}
                        />
                        <Button variant="outline-muted" style={{ borderBottomRightRadius: 0 }} onClick={handleShow} title="Добавить проект">
                            <i className="bi bi-plus-lg"></i>
                        </Button>
                    </InputGroup>
                </ListGroup.Item>


                <div className='flex-grow-1 h-0 overflow-hidden'>

                    <SimpleBar style={{ maxHeight: '100%' }}>
                    {filteredProjects?.map((project) => (
                        <NavLink
                            className={'list-group-item list-group-item-action'}
                            to={'./' + project.projectId}
                            key={project.projectId}
                            onClick={() => { setActiveProject(project)}}
                        >
                            {project.name}
                        </NavLink>
                    ))}
                    </SimpleBar>
                    
                </div>

            </ListGroup>

            <Modal show={showModal} onHide={handleClose}>

                <Form onSubmit={onSubmitCreateNewProject}>

                    <Modal.Header closeButton>
                        <Modal.Title>Новый проект</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="projectName">
                            <Form.Label>Название проекта</Form.Label>
                            <Form.Control type="text" placeholder="Введите название проекта" value={newProjectName} onChange={e => setNewProjectName(e.target.value)}/>
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant="primary" type="submit">
                            Добавить
                        </Button>
                    </Modal.Footer>

                </Form>

            </Modal>
        </>
    );
}